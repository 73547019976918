import React, { createContext, useEffect, useMemo, useState } from "react"
import Cookies from "../components/Common/Cookies"

interface AppContextProps {
  isSize: string
  activeBorder: string
  setActiveBorder: any
  footerNavigation: string
  setFooterNavigation: any
}

export const AppContext = createContext<AppContextProps>({} as AppContextProps)

export const AppContextProvider: React.FunctionComponent<
  React.PropsWithChildren
> = ({ children }) => {
  const [_width, setWidth] = useState(0)
  const [isSize, Setissize] = useState("")
  const [activeBorder, setActiveBorder] = useState("")
  const [footerNavigation, setFooterNavigation] = useState("")
  const [cookiedata, setcookiedata] = useState<any>("true")

  const handleResize = () => {
    setWidth(window.innerWidth)
    if (window.screen.width >= 1020) {
      Setissize("web")
    } else if (window.screen.width <= 1019 && window.screen.width >= 720) {
      Setissize("tab")
    } else if (window.screen.width <= 719) {
      Setissize("mobile")
    }
  }

  const onLoadBorder = () => {
    if (typeof window !== "undefined") {
      const currentPath = window.location.pathname
      if (currentPath.startsWith("/biography")) {
        setActiveBorder("/biography")
      } else if (currentPath.startsWith("/companies")) {
        setActiveBorder("/companies")
      } else if (currentPath.startsWith("/resources")) {
        setActiveBorder("/resources")
      } else if (currentPath.startsWith("/sustainability")) {
        setActiveBorder("/sustainability")
      } else if (currentPath.startsWith("/contact")) {
        setActiveBorder("/contact")
      } else {
        setActiveBorder("/")
      }
    }
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.screen.width)
      window.addEventListener("resize", handleResize)
      return () => {
        window.removeEventListener("resize", handleResize)
      }
    }
  }, [])

  useEffect(() => {
    handleResize()
    onLoadBorder()
    const cookiesvalue = getCookieValue()
    if (cookiesvalue || cookiesvalue != undefined || cookiesvalue != null) {
      setcookiedata(cookiesvalue)
    }
  }, [])

  const getCookieValue = () => {
    const cookies = document.cookie.split(";")
    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.split("=")
      if (cookieName.trim() === "hbcookies") {
        return decodeURIComponent(cookieValue)
      }
    }
    return null
  }

  return (
    <>
      {cookiedata === "true" || cookiedata === null ? <Cookies /> : ""}

      <AppContext.Provider
        value={useMemo(
          () => ({
            isSize,
            activeBorder,
            setActiveBorder,
            footerNavigation,
            setFooterNavigation,
          }),
          [isSize, activeBorder, footerNavigation]
        )}
      >
        {children}
      </AppContext.Provider>
    </>
  )
}
