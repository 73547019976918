import React, { HtmlHTMLAttributes, useState } from "react"
import "../../styles/global.scss"
import * as styles from "./common.module.scss"
import close from "../../images/x-close.png"
import { Link } from "gatsby"

const Cookies: React.FC = () => {
  const [cookiesActive, setCookiesActive] = useState(true)

  const addcookies = () => {
    const expiryDate = new Date()
    expiryDate.setDate(expiryDate.getDate() + 365)
    const activevalue = "false"
    const cookieValue = `${activevalue}; expires=${expiryDate.toUTCString()}; path=/`
    document.cookie = "hbcookies=" + cookieValue
    setCookiesActive(false)
  }

  return (
    <>
      <div
        className={`cookies ${styles.cookies} ${
          cookiesActive ? styles.active : styles.inactive
        }  `}
      >
        <div className="container-fluid">
          <div className={`row ${styles.cookiescontentposition}`}>
            <div className="col-md-9 d-flex align-items-center">
              <p>
                This website uses cookies from us and our business partners to
                enhance your browsing experience. For more information,
                including how to manage your cookie settings, please check our
                {" "}
                <span
                  // type="button"
                  className="cursor-pointer"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                  onClick={() => {
                    setCookiesActive(false)
                  }}
                >
                  Cookies Policy{" "}
                </span>
                and{" "}
                <span
                  className="cursor-pointer"
                  data-bs-toggle="modal"
                  data-bs-target="#pp"
                >
                  Privacy Policy
                </span>
                .
              </p>
            </div>
            <div
              className={`col-md-2 d-flex align-items-center cookiesBtn ${styles.cookiesposition}`}
            >
              <button
                className={`${styles.button} button1 align-items-center fs-14 fw-5 lh-19 text-white`}
                onClick={() => {
                  addcookies()
                }}
              >
                I UNDERSTAND{" "}
              </button>
            </div>

            <div className="col-md-1 d-flex align-items-center justify-content-center closeBtn cursor-pointer">
              {/* <img
                src={close}
                className="img-fluid"
                onClick={() => {
                  setCookiesActive(false)
                }}
                alt=""
              /> */}
              <button
                className={`${styles.button} button2 fs-14 fw-5 lh-19 text-white`}
                onClick={() => {
                  setCookiesActive(false)
                }}
              >
                DECLINE
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade cookiesPop"
        id="pp"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable modal-xl cookiesPop">
          <div className="modal-content">
            <div className="modal-header">
              <h3
                className="modal-title modal-title-privacy"
                id="staticBackdropLabel"
              >
                PRIVACY POLICY
              </h3>

              {/* <div>
                                <h5>PRIVACY STATEMENT</h5>
                            </div> */}

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              This Privacy Policy explains how the ‘Hind Bahwan Group’
              (hereinafter referred to as "HB", "we", "us", "our") may collect,
              use, store, disclose, discard, or otherwise process your data
              including personal data provided when using our websites and
              describes the rights you have with respect to your personal data.
              HB is committed to providing the highest level of protection
              regarding the processing of their vendors’/clients’/customers’/job
              applicants’/website visitors’ personal data based on applicable
              data protection laws and regulations at respective locations. In
              general, you can visit the Hind Bahwan website without providing
              personal data. However, there are times when HB will request
              personal data.
              <br />
              <br />
              <strong>Personal Data</strong>
              <p>
                In this Privacy Policy, “personal data” is any data which
                relates to an individual who may be identified from that data,
                or from a combination of a set of data, and other information
                which is or may be in possession of HB, for instance information
                about your identity and contact details (such as name, email-ID,
                contact number).
              </p>
              <br />
              <strong>Your Rights in Relation to Personal Data </strong> <br />
              You have the following rights in relation to your personal data:
              <br />
              <ul>
                <li>To access the personal data held by HB about you.</li>
                <li>
                  To have your personal data corrected, for example, if it is
                  incomplete or incorrect.
                </li>
                <li>
                  To opt out of receiving marketing communications at any time.
                </li>
                <li>
                  To restrict or object to the processing of personal data or
                  request erasing personal data (in certain circumstances and
                  subject to applicable law).
                </li>
                <li>
                  To receive a copy of the personal data which you have provided
                  to HB (in certain circumstances and subject to applicable
                  law).
                </li>
              </ul>
              <br />
              <strong>Purposes for which we process Personal Data </strong><br/>
              HB may process your personal data for legitimate purposes as
              follows, but not limited to:
              <ul>
                <li>
                  Marketing / promoting and providing products and services.
                </li>
                <li>
                  Monitoring and improving our website and its content.
                  Conducting market research and surveys with the aim of
                  improving / demonstrating our products and services.
                </li>
                <li>
                  Conducting market research and surveys with the aim of
                  improving / demonstrating our products and services.
                </li>
                <li>
                  Complying with applicable local or foreign law, regulation,
                  policy, voluntary codes, directive.
                </li>
                <li>
                  Establishing, exercising, or defending legal rights in
                  connection with legal proceedings (including any prospective
                  legal proceedings) and seeking professional or legal advice in
                  relation to such legal proceedings.
                </li>
              </ul>
              HB processes your personal data for the performance of the
              contracts, employment concluded with you, compliance with
              applicable legal or regulatory obligations or HB’s legitimate
              interests to provide you with adequate and qualitative information
              about products and services and to prevent against any excessive
              risk.
              <br />
              <br />
              <strong>Transfer of Personal Data </strong>
              <p>
                HB may transfer personal data, to any other body corporate or a
                person located in any other country. HB shall ensure that data
                protection is adhered to by HB as per the global rules and
                regulations. The transfer may be allowed only if it is necessary
                for the performance of the lawful contract between HB or any
                person on its behalf.
              </p>
              <br />
              <strong>Data Retention </strong>
              <p>
                Personal data is important to us and HB has invested
                significantly in technology and processes to protect the
                safeguarding and confidentiality of your personal data. If HB
                engages external service providers to act as processor, we
                ensure that they adhere to the same standards as HB.
                Irrespective of where your personal information is transferred
                or stored, we take all necessary steps to ensure that personal
                data is kept secure.
              </p>
              <br />
              <strong>Changes to this Privacy Policy </strong>
              <p>
                HB may update this policy from time to time. The use of the Hind
                Bahwan Websites and any products and services supplied are
                subject to our Terms and Conditions.
              </p>
              <br />
              <strong>Contact Details </strong>
              <p>
                If you have any questions regarding this privacy policy or the
                protection of your personal data, you may reach out to the HB
                Data Privacy Officer at <u>DPO@bahwancybertek.com </u>.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal modal-cookies fade cookiesPop"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        // tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Cookie Policy{" "}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setCookiesActive(true)
                }}
              ></button>
            </div>
            <div className="modal-body">
              <p>
                At Hind Bahwan Group, we are committed to safeguarding your
                privacy and ensuring that your personal information is
                protected. This Cookie Policy explains how we use cookies and
                similar technologies on our website, in accordance with
                international industry standards and best practice frameworks
                for security and governance.
              </p>
              <br />
              <strong>1. What Are Cookies?</strong>
              <p>
                Cookies are small text files placed on your device (computer,
                smartphone, or tablet) when you visit a website. They help
                websites function efficiently, enhance user experience, and
                provide information to the site owners.
              </p>
              <strong>2. Types of Cookies We Use</strong>
              <p>We use the following types of cookies:</p>
              <ul>
                <li>
                  <strong>Essential Cookies:</strong> Necessary for the basic
                  functioning of our website. They enable core functionalities
                  like security, network management, and accessibility.
                </li>
                <li>
                  <strong>Performance Cookies:</strong> Collect information
                  about how you use our website, such as pages visited, and any
                  errors encountered. These cookies help us improve our site's
                  performance.
                </li>
                <li>
                  <strong>Functional Cookies:</strong> Allow our website to
                  remember choices you make (e.g., language preferences) to
                  provide enhanced, personalised features.
                </li>
                <li>
                  <strong>Targeting/Advertising Cookies:</strong> Used to
                  deliver content relevant to your interests and measure the
                  effectiveness of our marketing campaigns.
                </li>
                <li>
                  <strong>Third-Party Cookies:</strong> Set by external services
                  we use on our website (e.g., analytics tools, social media
                  platforms).
                </li>
              </ul>
              <strong> 3. How We Use Cookies</strong>
              <p>We use cookies to:</p>
              <ul>
                <li>
                  <strong>Enhance User Experience:</strong> Remember your
                  preferences and settings.
                </li>
                <li>
                  <strong>Improve Website Performance:</strong> Analyse site
                  traffic and user interactions to optimise functionality.
                </li>
                <li>
                  <strong>Marketing Purposes:</strong> Deliver personalised
                  content and advertisements.
                </li>
                <li>
                  <strong>Security Measures:</strong> Protect against fraudulent
                  activities and enhance site security.
                </li>
              </ul>
              <br />
              <strong>4. Cookies in Use</strong>
              <p>Below is a list of specific cookies we use:</p>
              <div style={{ overflowX: "auto" }}>
                <table style={{ borderCollapse: "collapse", width: "100%" }}>
                  <thead>
                    <tr>
                      <th style={{ border: "1px solid black", padding: "8px" }}>
                        Cookie Provider
                      </th>
                      <th style={{ border: "1px solid black", padding: "8px" }}>
                        Cookie Name
                      </th>
                      <th style={{ border: "1px solid black", padding: "8px" }}>
                        Type
                      </th>
                      <th style={{ border: "1px solid black", padding: "8px" }}>
                        Purpose
                      </th>
                      <th style={{ border: "1px solid black", padding: "8px" }}>
                        Duration
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ border: "1px solid black", padding: "8px" }}>
                        hindbahwangroup.com
                      </td>
                      <td style={{ border: "1px solid black", padding: "8px" }}>
                        session_id
                      </td>
                      <td style={{ border: "1px solid black", padding: "8px" }}>
                        Essential
                      </td>
                      <td style={{ border: "1px solid black", padding: "8px" }}>
                        Maintains user session data
                      </td>
                      <td style={{ border: "1px solid black", padding: "8px" }}>
                        Session
                      </td>
                    </tr>
                    <tr>
                      <td style={{ border: "1px solid black", padding: "8px" }}>
                        Google Analytics
                      </td>
                      <td style={{ border: "1px solid black", padding: "8px" }}>
                        analytics_id
                      </td>
                      <td style={{ border: "1px solid black", padding: "8px" }}>
                        Performance
                      </td>
                      <td style={{ border: "1px solid black", padding: "8px" }}>
                        Tracks site usage for analytics
                      </td>
                      <td style={{ border: "1px solid black", padding: "8px" }}>
                        6 months
                      </td>
                    </tr>

                    <tr>
                      <td style={{ border: "1px solid black", padding: "8px" }}>
                        Google Analytics
                      </td>
                      <td style={{ border: "1px solid black", padding: "8px" }}>
                        third_party_tool
                      </td>
                      <td style={{ border: "1px solid black", padding: "8px" }}>
                        Third-Party
                      </td>
                      <td style={{ border: "1px solid black", padding: "8px" }}>
                        Integrates third-party services (e.g., social media
                        sharing)
                      </td>
                      <td style={{ border: "1px solid black", padding: "8px" }}>
                        Varies by provider
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p>
                Please note that the duration of third-party cookies is
                determined by the respective providers.
              </p>
              <br />
              <strong>5. Managing Your Cookie Preferences</strong>
              <p>
                <strong>Consent:</strong> By using our website, you consent to
                the placement of cookies on your device. Upon your first visit,
                you will be prompted to accept or customise your cookie
                preferences.
              </p>
              <p>
                <strong>Browser Settings:</strong> You can manage or disable
                cookies through your browser settings. However, blocking certain
                cookies may impact your experience and the functionality of our
                website.
              </p>
              <ul>
                <li>
                  <strong>For Chrome Users:</strong>{" "}
                  <Link to="https://support.google.com/accounts/answer/61416?hl=en&co=GENIE.Platform%3DDesktop">
                    Manage cookies in Chrome
                  </Link>
                </li>
                <li>
                  <strong>For Firefox Users:</strong>{" "}
                  <Link to="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox">
                    Enable and disable cookies in Firefox
                  </Link>
                </li>
                <li>
                  <strong>For Safari Users:</strong>{" "}
                  <Link to="https://support.apple.com/en-is/guide/safari/sfri11471/16.0/mac/11.0">
                    Manage cookies and website data in Safari
                  </Link>
                </li>
                <li>
                  {" "}
                  <strong>For Edge Users:</strong>{" "}
                  <Link to="https://support.microsoft.com/en-us/windows/manage-cookies-in-microsoft-edge-view-allow-block-delete-and-use-168dab11-0753-043d-7c16-ede5947fc64d#:~:text=and%20login%20information.-,Open%20Edge%20browser%2C%20select%20Settings%20and%20more%20in%20the%20upper,recommended)%20to%20allow%20all%20cookies.">
                    Block cookies in Microsoft Edge
                  </Link>
                </li>
              </ul>
              <strong>6. Security and Data Protection</strong>
              <p>
                We prioritise the security of your personal information. All
                data collected through cookies is stored securely, and we
                implement industry-standard measures to prevent unauthorised
                access, alteration, disclosure, or destruction of your data.
              </p>
              <strong>7. Third-Party Cookies</strong>
              <p>
                Our website may contain content from third parties, such as
                embedded videos or social media widgets, which may set their own
                cookies. We do not control these cookies, and we recommend
                reviewing the cookie policies of these third parties for more
                information.
              </p>
              <strong>8. International Data Transfers</strong>
              <p>
                Your information, including cookie data, may be transferred to
                and maintained on servers located outside of your jurisdiction.
                We ensure that such transfers comply with applicable data
                protection laws and that your information remains protected.
              </p>
              <strong>9. Updates to This Cookie Policy</strong>
              <p>
                We may update this Cookie Policy periodically to reflect changes
                in technology, legislation, or our data processing practices.
                Any updates will be posted on this page with an updated revision
                date.
              </p>
              <strong>10. Contact Us</strong>
              <p>
                If you have any questions or concerns about our use of cookies
                or this Cookie Policy, please contact us: <br />
                Email: DPO@bahwancybertek.com
              </p>
              <p>
                By continuing to use our website, you acknowledge that you have
                read and understood this, Cookie Policy.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Cookies
